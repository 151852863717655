<template>
  <CModal :show.sync="show" :title="$pgettext('photo_search.copy_to_gallery', 'Copy to Gallery')" centered>
    <PComponentLoader :loading="loading">
      <template v-if="show && !loading">
        <multiselect
            v-model="selected"
            :placeholder="$pgettext('photo_search.copy_to_gallery', 'Select Gallery')"
            label="name"
            track-by="id"
            :options="targetGalleries"
            group-values="sub"
            group-label="name"
            @input="onGallerySet"
        >
          <template #option="props">
            <div class="option__desc">
              <template v-if="props.option.$groupLabel">
                <span>{{ props.option.$groupLabel }}</span>
              </template>
              <template v-else>
                <span>{{ props.option.name }}</span>
              </template>

            </div>
          </template>
        </multiselect>
      </template>
    </PComponentLoader>
    <template #footer>
      <CButton @click="show = false" color="secondary" v-translate>Close</CButton>
      <PButton @click="onSetGallery" color="primary" translate-context="photo_search.copy_to_gallery" v-translate>
        Copy
      </PButton>
    </template>
  </CModal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PComponentLoader from "@/domain/core/components/PComponentLoader.vue";
import Multiselect from "vue-multiselect";
import PButton from "@/components/PButton.vue";

export default {
  name: "CopyToGalleryModal",
  components: {PButton, Multiselect, PComponentLoader},
  data() {
    return {
      loading: true,
      show: false,
      gallery: null,
      selected: null,
    }
  },
  computed: {
    ...mapGetters('photoSearch', [
      'targetGalleries',
    ]),
  },
  emits: ['copy'],
  methods: {
    ...mapActions('photoSearch', [
      'fetchGalleries',
    ]),
    async open(ids) {
      this.loading = true
      this.show = true
      this.gallery = null
      this.ids = ids
      await this.fetchGalleries()
      this.loading = false
    },
    async onSetGallery() {
      if (!this.gallery) {
        await this.notifyError(this.$pgettext('photo_search.copy_to_gallery', 'Please select a gallery'))
        return
      }
      if (this.selected.is_public) {
        await this.confirm({
          title: this.$pgettext('photo_search.copy_to_gallery', 'Set Gallery'),
          message: this.$gettextInterpolate(
              this.$gettext('Please note: the selected content will be copied to the "%{name}" gallery and delivered within the albums of all users. Do you want to proceed?'),
              {name: this.selected.name}),
          cb_confirm: async () => {
            await this.copyToGallery()
          }
        })
        return
      }
      await this.copyToGallery()
    }, onGallerySet(value) {
      this.selected = value
      this.gallery = value.id
    }, async copyToGallery() {
      await this.emitPromised('copy', {gallery: this.gallery, ids: this.ids})
      this.show = false
    }
  }
}
</script>

<style scoped>

</style>