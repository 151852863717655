<template>
  <CContainer fluid>
    <CRow v-for="cluster in gridClusters" :key="cluster.key" class="j-cluster gutter-1" :data-key="cluster.key">
      <CCol col="12" v-if="hasClusterGroups">
        <div class="d-flex photo-browser__grouper align-items-center" :id="cluster.id">
          <CInputCheckbox @click="onSelectCluster(cluster)" custom :checked="cluster.selected" :class="cluster.class"/>
          <span class="photo-browser__grouper__tile">{{ cluster.name }}</span>
        </div>
      </CCol>
      <CCol col="12">
        <div class="grid-thumb-container" :class="[thumbSize]">
          <div class="grid-thumb-item" v-for="photo in cluster.media" :key="photo.id"
               :class="{'d-none':!canSeePreImage(photo) }">
            <GridImage
                v-if="canSeePreImage(photo)"
                :photo="photo"
                :selection-mode="hasSelected"
                :selected="photo.id in selected"
                :hovering="photo.id in hovering"
                :preLoadable="photo.id in preLoadable"
                :active="searchResult && searchResult.id === photo.id"
                :found="searchResults && photo.id in searchResults"
                @selected="onSelect"
                @unselected="onUnselect"
                @zoom="onZoom"
                @hover="$emit('hover', $event)"
                @dragging="$emit('dragging', $event)"
                @dragover="$emit('dragover', $event)"
                @dragOverMedia="$emit('dragOverMedia', $event)"
                @contextmenu="$emit('contextmenu', $event)"
            />
          </div>
          <template v-if="sortingPosition != null">
            <div class="grid-thumb-item">
              <DropPlaceholder/>
            </div>
            <div class="grid-thumb-item" v-for="photo in cluster.media" :key="`${photo.id}-after`"
                 :class="{'d-none':!canSeePostImage(photo)}">
              <GridImage
                  v-if="canSeePostImage(photo)"
                  :photo="photo"
                  :selection-mode="hasSelected"
                  :selected="photo.id in selected"
                  :hovering="photo.id in hovering"
                  :active="searchResult && searchResult.id === photo.id"
                  :found="searchResults && photo.id in searchResults"
                  :preLoadable="photo.id in preLoadable"
                  @selected="onSelect"
                  @unselected="onUnselect"
                  @zoom="onZoom"
                  @dragging="$emit('dragging', $event)"
                  @dragover="$emit('dragover', $event)"
                  @dragOverMedia="$emit('dragOverMedia', $event)"
                  @contextmenu="$emit('contextmenu', $event)"
              />
            </div>
          </template>
        </div>
      </CCol>
    </CRow>
    <ZoomModal ref="zoomModal"
               :data="zoom"
               @change="onChangeImage"
               @download="downloadSingle"/>
  </CContainer>
</template>

<script>
import GridImage from "@/domain/photoSearch/components/GirdImage.vue";
import {mapActions, mapGetters} from "vuex";
import MediaCluster from "@/domain/photoSearch/mediaCluster";
import DropPlaceholder from "@/domain/photoSearch/components/DropPlaceholder.vue";
import ZoomModal from "@/domain/photoSearch/components/ZoomModal.vue";

export default {
  name: "Cluster",
  components: {ZoomModal, DropPlaceholder, GridImage},
  emits: ['download', 'selected', 'unselected', 'dragging', 'dragOverMedia', 'dragover', 'contextmenu', 'hover'],
  props: {
    selected: {
      type: Object,
      required: true
    },
    hovering: {
      type: Object,
      required: true
    },
    scale: {
      type: Number,
      default: 1
    },
    sortingPosition: {
      type: Number,
      required: false,
      default: null
    },
    sortingDirection: {
      type: Number,
      required: false,
      default: null
    },
    preLoadable: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapGetters('event', [
      'isPromo',
    ]),
    ...mapGetters('photoSearch', [
      'clusters',
      'clusterBy',
      'searchResult',
      'searchResults',
      'permissions',
    ]),
    hasClusterGroups() {
      return this.clusterBy !== MediaCluster.NO_CLUSTER
    },
    thumbSize() {
      switch (this.scale) {
        case 1:
          return 'small'
        case 2:
          return 'medium'
        default:
          return 'large'
      }
    },
    hasSelected() {
      return Object.keys(this.selected).length > 0
    },
    gridClusters() {
      return this.clusters.map((cluster) => {
        let nSelected = this.hasSelected ? cluster.media.filter(photo => photo.id in this.selected).length : 0
        if (nSelected <= 0) {
          cluster.class = 'off'
          cluster.selected = false
        } else {
          // If all the item of a cluster are selected, we can assume that there was a fetch for the ids of this cluster or the cluster is complete
          // since a user cannot select the last item of a cluster without triggering a next page fetch
          // It's not perfect, since one could change the filter or other things that would change the cluster, but it's a good enough approximation

          // The problem lies in the fact that selected is a mere list of ids, since we don't have all the ids of a cluster until it's complete
          // we can't know if all the items are selected without fetching the ids, if we want to improve this, we should add the cluster key to every selected item
          // it's a data we have, since the select came from a specific cluster, this would change the selected structure and break a lot of things, but it's doable
          cluster.selected = nSelected === cluster.media.length
          cluster.class = nSelected === cluster.media.length ? 'on' : 'partial'
        }
        cluster.id = `j-token-${cluster.key}`
        cluster.name = this.getClusterName(cluster.date)
        return cluster
      })
    },
  },
  data() {
    return {
      zoom: {
        src: null,
        fileName: null,
        has_prev: false,
        has_next: false,
      },
    }
  },
  methods: {
    ...mapActions('photoSearch', ['fetchIdsCluster']),
    onSelect(photo) {
      this.$emit('selected', [photo])
    }, onUnselect(photo) {
      this.$emit('unselected', [photo])
    }, async onSelectCluster(cluster) {
      let selected = cluster.media.filter(photo => photo.id in this.selected)
      if (selected.length === cluster.media.length) {
        if (cluster.complete)
          this.$emit('unselected', cluster.media)
        else {
          const ids = await this.fetchIdsCluster(cluster.key)
          this.$emit('unselected', ids.map((id) => {
            return {id: id}
          }))
        }
      } else {
        if (cluster.complete)
          this.$emit('selected', cluster.media)
        else {
          const ids = await this.fetchIdsCluster(cluster.key)
          this.$emit('selected', ids.map((id) => {
            return {id: id}
          }))
        }
      }
    },
    downloadSingle(photo) {
      this.$emit('download', {ids: [photo.id]})
    },
    getClusterName(date) {
      if (!date) return ''
      if (this.clusterBy === 'minute')
        return date.format('YYYY-MM-DD HH:mm')
      if (this.clusterBy === '10minute')
        return date.minute(Math.floor(date.minute() / 10) * 10).format('YYYY-MM-DD HH:mm')
      if (this.clusterBy === 'hour')
        return date.format('YYYY-MM-DD HH')
      if (this.clusterBy === 'day')
        return date.format('YYYY-MM-DD')
      throw new Error('Invalid cluster by')
    },
    updateZoomProps(photo) {
      this.zoom.photo = photo
      this.zoom.src = photo.sd ?? photo.thumb
      this.zoom.fileName = photo.file_name
      this.zoom.shot_at = photo.shot_at ? photo.shot_at.format('YYYY-MM-DD HH:mm:ss') : ''
      this.zoom.is_public = photo.is_public

      const clusterIndex = this.gridClusters.findIndex(cluster =>
          cluster.media.some(p => p.id === photo.id)
      )
      if (clusterIndex === -1) {
        this.zoom.has_prev = false
        this.zoom.has_next = false
        return
      }

      const cluster = this.gridClusters[clusterIndex]
      const photoIndex = cluster.media.findIndex(p => p.id === photo.id)

      this.zoom.has_prev = photoIndex > 0 || clusterIndex > 0
      this.zoom.has_next = photoIndex < cluster.media.length - 1 || clusterIndex < this.gridClusters.length - 1
    },
    onZoom(photo) {
      this.updateZoomProps(photo)
      this.$refs.zoomModal.open()
    },
    onChangeImage(direction) {
      const newMedia = this.getNewMedia(direction)
      this.updateZoomProps(newMedia)
      this.$emit('scroll',newMedia)
    },
    getNewMedia(direction) {
      let index = this.gridClusters.findIndex((cluster) => {
        return cluster.media.some((photo) => photo.id === this.zoom.photo.id)
      })
      if (index === -1) return
      let cluster = this.gridClusters[index]
      let photoIndex = cluster.media.findIndex((photo) => photo.id === this.zoom.photo.id)
      if (direction === 'prev') {
        if (photoIndex > 0) {
          return cluster.media[photoIndex - 1]
        } else if (index > 0) {
          let prevCluster = this.gridClusters[index - 1]
          return prevCluster.media[prevCluster.media.length - 1]
        }
      } else if (direction === 'next') {
        if (photoIndex < cluster.media.length - 1) {
          return cluster.media[photoIndex + 1]
        } else if (index < this.gridClusters.length - 1) {
          let nextCluster = this.gridClusters[index + 1]
          return nextCluster.media[0]
        }
      }
    },
    canSeePostImage(photo) {
      if (!this.sortingPosition || this.sortingDirection === 0) return false
      if (this.sortingDirection > 0) return photo.order > this.sortingPosition
      else return photo.order >= this.sortingPosition
    },
    canSeePreImage(photo) {
      if (!this.sortingPosition || this.sortingDirection === 0) return true
      if (this.sortingDirection > 0) return photo.order <= this.sortingPosition
      else return photo.order < this.sortingPosition
    },
  }
}
</script>