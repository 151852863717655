<template>
  <CRow>
    <CCol>
      <PAlert color="warning"
              content-align="align-items-center"
              extra-class="p-3"
              :title="$pgettext('browse_photo', 'Activate Event')"
              :text="$pgettext('browse_photo', 'To be able to upload photos you must first activate the event')"
              icon="cipAlertTriangle">
        <template #cta>
          <CLink
              @click="$emit('publish')"
              v-if="hasPerm('event_wizard.publish')">
            <translate translate-context="browse_photo">Activate</translate>
            <CIcon name="cipArrowNarrowUpRight" class="ml-1" size="custom" width="20"/>
          </CLink>
        </template>
      </PAlert>
    </CCol>
  </CRow>
</template>

<script>

import PAlert from "@/components/PAlert.vue";

export default {
  name: "IsDraftMessage",
  components: {PAlert},
  emits: ['publish']
}
</script>