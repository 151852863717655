<template>
  <CModal :show.sync="show" centered class="">
    <div class="d-flex flex-column justify-content-center align-items-center published-event-modal">
      <div style="margin-bottom:28px;">
        <CIcon ref="iconCheck" name="cipCircleCheck" size="custom-size" width="56" class="text-primary invisible"/>
      </div>
      <div class="text-center">
        <div class="title" v-translate translate-context="event.modal.endprocess.title">
          Photo processing completed
        </div>
        <p class="text" v-translate translate-context="event.modal.endprocess.description">
          All photos are now available for viewing by event participants, do you want to upload more photos or communicate the event?
        </p>
      </div>
    </div>
    <template #footer>
      <div class="d-flex w-100">
        <div class="flex-grow-1 mr-1">
          <CButton block color="secondary" @click="close" v-translate>Upload more photos</CButton>
        </div>
        <div class="flex-grow-1 ml-1">
          <PButton block color="primary" @click="onConfirm" v-translate>Communicate the event</PButton>
        </div>
      </div>
    </template>
  </CModal>
</template>

<script>
import PButton from "@/components/PButton.vue";

export default {
  name: "ProcessingEndModal",
  components: {PButton},
  emits: ['confirm'],
  data() {
    return {
      show: false
    }
  },
  methods: {
    async open() {
      this.show = true
      await this.$nextTick()
      await this.delay(800)
      this.$refs.iconCheck.$el.classList.remove('invisible')
      this.$refs.iconCheck.$el.classList.add('draw-svg')
    },
    close() {
      this.show = false
    },
    async onConfirm() {
      await this.$emit('confirm')
    }
  }
}
</script>