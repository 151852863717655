<template>
  <div class="position-relative">
    <DownloadModal :selected="selected" @download="onDownload" ref="downloadModal"/>
    <TagSelectorModal @add="onAddTags" ref="tagSelectorModal"/>
    <SetGalleryModal @set="onSetGallery" ref="setGalleryModal"/>
    <CopyToGalleryModal @copy="onCopyToGallery" ref="copyToGalleryModal"/>
    <ProcessingEndModal ref="processingEndModal" @confirm="routeToOverview"/>
    <UploadReceiver ref="uploadReceiver" v-if="permissions.canUpload"/>
    <PContextMenu ref="contextMenu" :menu-items="contextItems" @item-selected="onContextMenuSelected"/>
    <PFileDrop @dropped="onFileDropped" ref="fileDrop" multiple class="p-file-drop mb-4" style="display: none;"/>
    <Spinner v-if="configLoading"/>
    <template v-else>
      <CRow v-if="!in_tab">
        <CCol>
          <h1 class="main-header mb-1" v-translate>Browse photos</h1>
        </CCol>
      </CRow>
      <IsDraftMessage @publish="openPublishEventModal" v-if="isDraft"/>
      <template v-else>
        <div @drop.prevent="drop" @dragover.prevent="dragover" @dragleave="dragleave" ref="preUploader"
             :class="{'is-dragging': isDragging}">
          <div ref="uploadHeader" id="uploadHeader">
            <BannerMessages :gallery="gallery" @dismiss="updateContentHeight"/>
            <TopBar
                :canUpload="canUpload"
                :scale="scale"
                :active-tab="activeTab"
                @filter="onFilter"
                @upload="openUploaderModal"
                @scale="setScale"
                @changeTab="onChangeTab"
                @sorting="onChangeSorting"
                v-if="showTopBar"
            />
          </div>
          <Spinner v-if="tabLoading"/>
          <NoMedia :tab="activeTab" @upload="openUploaderModal" :style="contentHeight" v-else-if="!hasMedia"/>
          <CRow v-else>
            <CCol col="12" class="photo-browser">
              <TimeLine :activeKey="activeKey" @update:activeKey="onClickTimeLine" v-if="hasTimeline && !hideTimeline"/>
              <CRow>
                <CCol :class="{ 'no-select': shiftPressed }">
                  <div class="photo-browser__medias" @dragover.prevent="onExternalDragOver">
                    <InfiniteScroll
                        @begin="fetchBefore"
                        @end="fetchAfter"
                        @update:visible="onChangeFirstVisibleMedia"
                        @selected="onSelect"
                        @unselected="onUnselect"
                        @hover="onHover"
                        @dragging="onDragging"
                        @dragOverMedia="onDragOverMedia"
                        @contextmenu="openContextMenu"
                        @download="onDownload"
                        :selected="selected"
                        :hovering="hovering"
                        :sortingPosition="sortingPosition"
                        :sortingDirection="dragDirection"
                        :timeZone="timeZone"
                        :has-before="hasBefore"
                        :has-after="hasAfter"
                        :scale="scale"
                        :in_tab="in_tab"
                        :has_timeline="hasTimeline && !hideTimeline"
                        :is_sorting="isSorting"
                        :style="contentHeight"
                        ref="infiniteScroll"
                    />
                  </div>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
        <BottomBar :numSelected="numSelected"
                   :actions="menuItems"
                   :activeTab="activeTab"
                   @reset="onResetSelected"
                   @action="onContextMenuSelected"
                   @deliver="onDeliver"
                   @show="onShowHidden"
                   v-if="!configLoading && !tabLoading"/>
      </template>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TimeLine from "@/domain/photoSearch/components/TimeLine.vue";
import InfiniteScroll from "@/domain/photoSearch/components/InfiniteScroll.vue";
import TopBar from "@/domain/photoSearch/components/TopBar.vue";
import DownloadModal from "@/domain/photoSearch/components/DownloadModal.vue";
import TagSelectorModal from "@/domain/photoSearch/components/TagSelectorModal.vue";
import SetGalleryModal from "@/domain/photoSearch/components/SetGalleryModal.vue";
import fileUtils from "@/domain/core/utils/fileUtils";
import PContextMenu from "@/domain/core/components/PContextMenu.vue";
import GallerySorting from "@/domain/core/constant/gallerySorting";
import {ValidationError} from "@/domain/core/exception/exceptions";
import MessageManager from "@/domain/core/utils/messageManager";
import UploadReceiver from "@/domain/uploader/components/UploadReceiver.vue";
import mqttTopics from "@/domain/core/constant/mqttTopics";
import PFileDrop from "@/domain/core/components/upload/PFileDrop.vue";
import {cloneDeep} from "lodash";
import photoSearchTab from "@/domain/core/constant/photoSearchTab";
import IsDraftMessage from "@/domain/photoSearch/components/IsDraftMessage.vue";
import BannerMessages from "@/domain/photoSearch/components/BannerMessages.vue";
import Spinner from "@/domain/photoSearch/components/Spinner.vue";
import NoMedia from "@/domain/photoSearch/components/NoMedia.vue";
import ProcessingEndModal from "@/domain/photoSearch/components/ProcessingEndModal.vue";
import BottomBar from "@/domain/photoSearch/components/BottomBar.vue";
import CopyToGalleryModal from "@/domain/photoSearch/components/CopyToGalleryModal.vue";

export default {
  name: 'PhotoSearch',
  components: {
    CopyToGalleryModal,
    BottomBar,
    ProcessingEndModal,
    NoMedia,
    Spinner,
    BannerMessages,
    IsDraftMessage,
    PFileDrop,
    TopBar,
    UploadReceiver,
    PContextMenu,
    SetGalleryModal,
    TagSelectorModal,
    DownloadModal,
    InfiniteScroll,
    TimeLine
  },
  data() {
    return {
      scale: 1,
      isProcessStarted: false,
      selected: {},
      hovering: {},
      shiftPressed: false,
      activeKey: null,
      lastSelected: null,
      lastHovered: null,
      isDragging: false,
      isSorting: false,
      dragPosition: null,
      contextMedia: null,
      contentHeight: 'height: calc(100vh - 234px);'
    }
  },
  props: {
    gallery: {
      type: Object,
      required: false,
      default: null
    },
  },
  async mounted() {
    this.setTimezone(this.timeZone)
    await this.onChangeDestination(this.$route.params.picaServiceId, this.gallery)
    await this.fetchConfig()

    if (this.$route.query.pica_code && this.permissions.canFilterByPicaCode)
      this.setFilters({pica_code: this.$route.query.pica_code})

    if (this.hasTimeline) {
      await this.fetchTimeline()
      await this.$nextTick()
    }

    let promises = []
    promises.push(this.fetchMedia())
    promises.push(this.fetchGalleries())
    if (this.permissions.canUpdateTags) promises.push(this.fetchTags())
    await Promise.all(promises)

    await this.updateContentHeight()

    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
    window.addEventListener('resize', this.updateContentHeight)

  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)
    window.removeEventListener('resize', this.updateContentHeight)
    MessageManager.off('photoSearch')
  },
  computed: {
    ...mapGetters('photoSearch', [
      'configLoading',
      'tabLoading',
      'hasMedia',
      'hasActiveFilters',
      'hasTimeline',
      'hideTimeline',
      'timeline',
      'hasBefore',
      'hasAfter',
      'permissions',
      'media',
      'searchResult',
      'sorting',
      'galleries',
      'availableTags',
      'activeFilters',
      'filters',
      'singleMedia'
    ]),
    ...mapGetters('event', [
      'timeZone',
      'isDraft'
    ]),
    ...mapGetters('uploader', [
      'galleryStats',
      'hasProcessingMedia',
      'remainingUpload',
      'hasGlobalProcessingMedia'
    ]),
    canUpload() {
      if (!this.permissions.canUpload) return false
      return this.remainingUpload > 0
    },
    in_tab() {
      return this.gallery && this.gallery.id !== null
    },
    showTopBar() {
      if (this.hasProcessingMedia) return true
      if (this.hasMedia) return true
      if (this.hasActiveFilters) return true
      return false
    },
    numSelected() {
      if (!this.selected) return 0
      return Object.keys(this.selected).length
    }, uploadDisabled() {
      return !this.permissions.canUpload || this.isDraft
    }, sortingPosition() {
      return this.isSorting ? this.dragPosition?.order : null
    }, selectedMinOrder() {
      return Math.min(...Object.values(this.selected).map(photo => photo.order))
    }, dragDirection() {
      if (this.dragPosition?.order === this.selectedMinOrder) return 0
      return this.dragPosition?.order > this.selectedMinOrder ? 1 : -1
    }, canSort() {
      return this.sorting === GallerySorting.custom && this.permissions.canSortMedia
    }, canSetGallery() {
      if (!this.permissions.canSetGallery) return false
      if (!this.galleries) return false
      if (this.isNonEditableMediaSelected) return false
      return this.galleries.filter(gallery => gallery.id !== this.gallery?.id).length > 0
    }, canCopy() {
      if (!this.permissions.canCopy) return false
      if (!this.galleries) return false
      if (this.isNonEditableMediaSelected) return false
      return this.galleries.filter(gallery => gallery.id !== this.gallery?.id).length > 0
    }, selectedMediaHasTags() {
      return Object.values(this.selected).some(photo => {
        if (!photo.tags) return false
        return photo.tags.filter(tag => !tag.includes('g:')).length > 0
      })
    }, hasTagsToAdd() {
      if (this.validTagList.length === 0) return false
      return Object.values(this.selected).some(photo => {
        if (!photo.tags) return false
        if (!photo.editable) return false
        return this.validTagList.some(tag => !photo.tags.includes(tag))
      })
    }, hasMediaToRestore() {
      if (!this.permissions.canRestore) return false
      return Object.values(this.selected).some(photo => {
        return photo.soft_deleted === true && photo.editable
      })
    }, hasMediaToSoftDelete() {
      if (!this.permissions.canSoftDelete) return false
      return Object.values(this.selected).some(photo => {
        return !photo.soft_deleted && photo.editable
      })
    }, hasMediaToDelete() {
      if (!this.permissions.canDelete) return false
      return Object.values(this.selected).some(photo => {
        return photo.editable
      })
    }, validTagList() {
      if (!this.availableTags) return []
      return Object.keys(this.availableTags).reduce((acc, cat) => {
        Object.values(this.availableTags[cat]).forEach(tag => {
          acc.push(cat + ':' + tag)
        })
        return acc
      }, [])
    }, activeTab() {
      if (!this.filters) return photoSearchTab.all
      if ('unprocessed' in this.filters)
        return photoSearchTab.unprocessable
      if ('deleted' in this.filters)
        return photoSearchTab.deleted
      return photoSearchTab.all
    }, isNonEditableMediaSelected() {
      return Object.values(this.selected).some(photo => {
        return !photo.editable
      })
    },
    menuItems() {
      let options = []
      if (this.canSort) {
        if (options.length)
          options.push({divider: true})
        options.push({
          value: 'move_top',
          label: this.$pgettext('photo_search.action', 'Move to Top'),
          icon: 'cipArrowNarrowUp'
        })
        options.push({
          value: 'move_bottom',
          label: this.$pgettext('photo_search.action', 'Move to End'),
          icon: 'cipArrowNarrowDown'
        })
      }
      if (this.canSetGallery || this.canCopy) {
        if (options.length)
          options.push({divider: true})
        if (this.canSetGallery) {
          options.push({
            value: 'set_gallery',
            label: this.$pgettext('photo_search.action', 'Set Gallery'),
            icon: 'cipPencilLine'
          })
        }
        if (this.canCopy) {
          options.push({
            value: 'copy_to_gallery',
            label: this.$pgettext('photo_search.action', 'Copy'),
            icon: 'cipCopy'
          })
        }
      }
      if (this.permissions.canUpdateTags) {
        if (options.length && (this.hasTagsToAdd || this.selectedMediaHasTags))
          options.push({divider: true})
        if (this.hasTagsToAdd)
          options.push({
            value: 'add_tags',
            label: this.$pgettext('photo_search.action', 'Add Tags'),
            icon: 'cipTagPlus'
          })
        if (this.selectedMediaHasTags)
          options.push({
            value: 'remove_tags',
            label: this.$pgettext('photo_search.action', 'Remove Tags'),
            icon: 'cipTagMinus'
          })
      }
      if (this.permissions.canDownload) {
        if (options.length)
          options.push({divider: true})
        options.push({
          value: 'download',
          label: this.$pgettext('photo_search.action', 'Download'),
          icon: 'cipDownload03'
        })
      }

      if (this.hasMediaToRestore || this.hasMediaToSoftDelete) {
        if (options.length)
          options.push({divider: true})
        if (this.hasMediaToSoftDelete)
          options.push({
            value: 'soft_delete',
            label: this.$pgettext('photo_search.action', 'Hide photo'),
            icon: 'cipEyeOff'
          })
        if (this.hasMediaToRestore)
          options.push({
            value: 'restore',
            label: this.$pgettext('photo_search.action', 'Show photo'),
            icon: 'cipEye'
          })
      }
      if (this.hasMediaToDelete) {
        if (options.length)
          options.push({divider: true})
        options.push({
          value: 'delete_media',
          label: this.$pgettext('photo_search.action', 'Delete'),
          icon: 'cipTrashFull'
        })
      }
      return options
    },
    contextItems() {
      let options = []
      if (this.contextMedia !== null) {
        if (this.canSort) {
          options.push({
            value: 'move_before',
            label: this.$pgettext('photo_search.action', 'Move Before'),
            icon: 'cipArrowNarrowLeft'
          })
          options.push({
            value: 'move_after',
            label: this.$pgettext('photo_search.action', 'Move After'),
            icon: 'cipArrowNarrowRight'
          })
        }
        return options
      }
      if (this.canSort) {
        options.push({
          value: 'move_top',
          label: this.$pgettext('photo_search.action', 'Move to Top'),
          icon: 'cipArrowNarrowUp'
        })
        options.push({
          value: 'move_bottom',
          label: this.$pgettext('photo_search.action', 'Move to End'),
          icon: 'cipArrowNarrowDown'
        })
      }

      if (this.canSetGallery || this.canCopy) {
        if (options.length)
          options.push({divider: true})
        if (this.canSetGallery) {
          options.push({
            value: 'set_gallery',
            label: this.$pgettext('photo_search.action', 'Set Gallery'),
            icon: 'cipPencilLine'
          })
        }
        if (this.canCopy) {
          options.push({
            value: 'copy_to_gallery',
            label: this.$pgettext('photo_search.action', 'Copy'),
            icon: 'cipCopy'
          })
        }
      }

      if (this.permissions.canUpdateTags) {
        if (options.length && (this.hasTagsToAdd || this.selectedMediaHasTags))
          options.push({divider: true})
        if (this.hasTagsToAdd)
          options.push({
            value: 'add_tags',
            label: this.$pgettext('photo_search.action', 'Add Tags'),
            icon: 'cipTagPlus'
          })
        if (this.selectedMediaHasTags)
          options.push({
            value: 'remove_tags',
            label: this.$pgettext('photo_search.action', 'Remove Tags'),
            icon: 'cipTagMinus'
          })
      }

      if (this.permissions.canDownload) {
        if (options.length)
          options.push({divider: true})
        options.push({
          value: 'download',
          label: this.$pgettext('photo_search.action', 'Download'),
          icon: 'cipDownload03'
        })
      }

      if (this.hasMediaToRestore || this.hasMediaToSoftDelete) {
        if (options.length)
          options.push({divider: true})
        if (this.hasMediaToSoftDelete)
          options.push({
            value: 'soft_delete',
            label: this.$pgettext('photo_search.action', 'Hide photo'),
            icon: 'cipEyeOff'
          })
        if (this.hasMediaToRestore)
          options.push({
            value: 'restore',
            label: this.$pgettext('photo_search.action', 'Show photo'),
            icon: 'cipEye'
          })
      }

      if (this.hasMediaToDelete) {
        if (options.length)
          options.push({divider: true})
        options.push({
          value: 'delete_media',
          label: this.$pgettext('photo_search.action', 'Delete'),
          icon: 'cipTrashFull'
        })
      }
      return options
    },
  },
  watch: {
    gallery: {
      immediate: true,
      handler() {
        this.onChangeDestination(this.$route.params.picaServiceId, this.gallery)
      }
    },
    hasMedia() {
      this.updateContentHeight()
    },
    hasGlobalProcessingMedia(value) {
      if (value) return
      if (this.hasPerm('feature.popup_upload_completed')) this.$refs.processingEndModal.open()
    },
    async searchResult() {
      if (!this.searchResult) return

      let found = false
      if (this.$refs.infiniteScroll)
        found = this.$refs.infiniteScroll.scrollToMedia(this.searchResult.id)
      if (!found) {
        await this.fetchFromId(this.searchResult.id)
        await this.$nextTick()
        for (let i = 0; i < 5; i++) {
          await this.delay(100)
          found = this.$refs.infiniteScroll.scrollToMedia(this.searchResult.id)
          if (found) return
        }
      }
    },
  },
  methods: {
    ...mapActions('photoSearch', [
      'setPicaServiceId',
      'setTimezone',
      'downloadPhotos',
      'fetchConfig',
      'fetchTimeline',
      'fetchGalleries',
      'fetchBefore',
      'fetchAfter',
      'fetchFromId',
      'fetchForKey',
      'fetchFirstMedia',
      'setFilters',
      'setSorting',
      'setSearchQuery',
      'addTags',
      'removeTags',
      'setGallery',
      'copyToGallery',
      'newMediaUploaded',
      'newMediaProcessed',
      'reorder',
      'moveTop',
      'moveBottom',
      'deleteMedia',
      'fetchTags',
      'softDelete',
      'restore',
    ]),
    ...mapActions('uploader', [
      'setDestination',
    ]),
    async updateContentHeight() {
      await this.$nextTick()
      const uploadHeader = this.$refs.uploadHeader ? this.$refs.uploadHeader.offsetHeight : 0
      this.contentHeight = `height: calc(100vh - ${uploadHeader}px - 230px);`
    },
    async onChangeDestination(picaServiceId, gallery) {
      this.setPicaServiceId({
        picaServiceId: picaServiceId,
        galleryId: gallery?.id
      })
      await this.setDestination({
        picaServiceId: this.$route.params.picaServiceId,
        galleryId: gallery?.id
      })

      MessageManager.off('photoSearch')
      MessageManager.setParams({galleryId: gallery ? gallery.id : '*'})
      MessageManager.on(mqttTopics.upload.new_media_gallery, async (data) => {
        const taskId = data.data.task_id
        if (data.data.metrics.received)
          await this.newMediaUploaded(taskId)
        else if (data.data.metrics.processed)
          await this.newMediaProcessed(taskId)
      }, 'photoSearch')
    },
    onSelect(photos) {
      if (this.shiftPressed) {
        let hovering = Object.keys(this.hovering)
        for (let i = 0; i < hovering.length; i++) {
          if (!this.selected[hovering[i]])
            this.$set(this.selected, hovering[i], this.singleMedia(hovering[i]))
        }
        this.lastSelected = null
        this.lastHovered = null
      } else {
        for (let i = 0; i < photos.length; i++)
          if (!this.selected[photos[i].id])
            this.$set(this.selected, photos[i].id, photos[i])
        this.lastSelected = photos[photos.length - 1]
      }
    },
    onUnselect(photos) {
      if (this.shiftPressed) return

      for (let i = 0; i < photos.length; i++)
        if (this.selected[photos[i].id])
          this.$delete(this.selected, photos[i].id)
      this.lastSelected = null
    },
    onResetSelected() {
      this.selected = {}
      this.lastSelected = null
    },
    onChangeFirstVisibleMedia(key) {
      this.activeKey = key
    },
    onClickTimeLine(key) {
      this.activeKey = key
      const found = this.$refs.infiniteScroll.scrollToKey(key)
      if (!found)
        this.fetchMedia()
    },
    onDeliver() {
      const parent = this.getParentWithMethod('openPayAsYouGo')
      if (parent) parent.openPayAsYouGo()
    },
    async onShowHidden() {
      await this.confirm({
        color: 'primary',
        title: this.$pgettext('photo_search', 'Show all photos'),
        icon: 'cipEye',
        message: this.$pgettext('photo_search', 'This will show all the photos that have been hidden. Are you sure you want to proceed?'),
        cb_confirm: async () => {
          try {
            // TODO: create a specific endpoint, or use the 'selectAll' method, this.media does not contain all the photos (pagination)
            const ids = this.media.map(photo => photo.id)
            await this.restore(ids)
            await this.onChangeTab(photoSearchTab.all)
          } catch (e) {
            await this.handleActionErrors(e)
          }
        }
      })
    },
    async onChangeTab(newTab) {
      let filters = cloneDeep(this.filters)
      if (newTab === photoSearchTab.all) {
        delete filters.deleted
        delete filters.unprocessed
      } else if (newTab === photoSearchTab.deleted) {
        delete filters.unprocessed
        filters.deleted = true
      } else if (newTab === photoSearchTab.unprocessable) {
        delete filters.deleted
        filters.unprocessed = true
      }
      await this.onFilter(filters)
    },
    async fetchMedia() {
      if (this.hasTimeline && this.timeline.length) {
        if (!this.activeKey) this.activeKey = this.timeline[0].key
        await this.fetchForKey(this.activeKey)
      } else {
        await this.fetchFirstMedia()
      }

      this.lastHovered = null
      this.dragPosition = null
      this.lastSelected = null
    },
    async onFilter(filters) {
      this.setFilters(filters)
      let promises = []
      promises.push(this.fetchMedia())
      if (this.hasTimeline)
        promises.push(this.fetchTimeline())
      await Promise.all(promises)
      await this.updateContentHeight()
    },
    async onChangeSorting(sorting) {
      await this.setSorting(sorting)
      await this.fetchConfig()

      let promises = []
      promises.push(this.fetchMedia())
      if (this.hasTimeline)
        promises.push(this.fetchTimeline())
      await Promise.all(promises)
    },
    onKeyDown(event) {
      if (event.key !== 'Shift') return
      this.shiftPressed = true
      this.updateHovering()
    },
    onKeyUp(event) {
      if (event.key !== 'Shift') return
      this.shiftPressed = false
      this.updateHovering()
    },
    onHover(id) {
      this.lastHovered = id
      this.updateHovering()
    },
    updateHovering() {
      if (!this.shiftPressed || !this.lastSelected || !this.lastHovered) {
        this.hovering = {}
        return
      }

      let lastSelectedIndex = this.media.findIndex(photo => photo.id === this.lastSelected.id)
      let lastHoveredIndex = this.media.findIndex(photo => photo.id === this.lastHovered)
      let start = Math.min(lastSelectedIndex, lastHoveredIndex)
      let end = Math.max(lastSelectedIndex, lastHoveredIndex)

      this.hovering = {}
      for (let i = start; i <= end; i++)
        this.$set(this.hovering, this.media[i].id, this.media[i].id)
    },
    setScale(value) {
      this.scale = value
    },
    openPublishEventModal() {
      const parent = this.getParentWithMethod('openPublishEvent')
      if (parent) parent.openPublishEvent()
    },
    openAddTagsModal(ids) {
      this.$refs.tagSelectorModal.open(ids)
    },
    openSetGalleryModal(ids) {
      this.$refs.setGalleryModal.open(ids)
    },
    openCopyToGalleryModal(ids) {
      this.$refs.copyToGalleryModal.open(ids)
    },
    openUploaderModal() {
      this.$refs.fileDrop.open()
    },
    async drop(e) {
      this.isDragging = false
      this.isSorting = false

      if (this.isFileDropEvent(e)) {
        if (this.uploadDisabled) return
        const files = await fileUtils.getFilesFromDropEvent(e)
        await this.$refs.uploadReceiver.onFileDrop(files)
      } else {
        let sel = Object.keys(this.selected)
        await this.reorder({
          ids: sel,
          after: this.dragDirection > 0 ? this.dragPosition.id : null,
          before: this.dragDirection < 0 ? this.dragPosition.id : null
        })
        this.dragPosition = null
        this.selected = {}
      }
    },
    onFileDropped(files) {
      this.$refs.uploadReceiver.onFileDrop(files, this.gallery)
    },
    isFileDropEvent(e) {
      return e.dataTransfer.items.length > 0 && e.dataTransfer.items[0].kind === 'file'
    },
    dragover() {
      if (this.isSorting) return
      this.isDragging = true
    },
    dragleave(e) {
      //Prevents flickering during drag: Check if the related target is still within the parent element
      const related = e.relatedTarget
      if (related && this.$refs.preUploader.contains(related)) return

      if (this.isSorting) {
        this.dragPosition = this.media[0]
        return
      }

      this.isDragging = false
    },
    onDragging({event, photo}) {
      if (!this.canSort) return

      if (!this.selected[photo.id]) {
        this.selected = {}
        this.lastSelected = null
        this.$set(this.selected, photo.id, photo)
      }
      if (event.target.tagName === 'IMG')
        event.dataTransfer.setDragImage(event.target, 0, 0)
      this.isSorting = true
    },
    onDragOverMedia(photo) {
      this.dragPosition = photo
    },
    onExternalDragOver(event) {
      const target = event.target
      if (target.tagName === 'IMG')
        return false
      const validClasses = ['photo-browser__medias', 'infinite-scroll', 'j-cluster']
      if (!validClasses.some(className => target.classList.contains(className)))
        return false
      this.dragPosition = this.media[this.media.length - 1]
    },
    async openContextMenu({event, photo}) {

      if (Object.keys(this.selected).length > 0 && !this.selected[photo.id]) {
        if (this.canSort) {
          // if user can sort and right-click on an unselected item, this is the target of the action
          this.contextMedia = photo
        } else {
          // if the user cannot sort, then we probably want to select the item
          this.contextMedia = null
          this.selected = {}
          this.$set(this.selected, photo.id, photo)
        }
      } else {
        // right click on already select item, no target
        this.contextMedia = null
        this.$set(this.selected, photo.id, photo)
      }
      await this.$nextTick()
      if (this.contextItems.length > 0)
        this.$refs.contextMenu.open(event, photo)
      else
        this.$refs.contextMenu.close()
    },
    async onAddTags({ids, tags}) {
      await this.addTags({ids, tags})
      this.onResetSelected()
    },
    async onSetGallery({ids, gallery}) {
      await this.setGallery({ids, gallery})
      this.onResetSelected()
    },
    async onCopyToGallery({ids, gallery}) {
      await this.copyToGallery({ids, gallery})
      this.onResetSelected()
    },
    async onContextMenuSelected(action) {
      if (this.selected.length <= 0)
        return this.notifyError(this.$pgettext('photo_search', 'Please select at least one file'))
      const ids = Object.keys(this.selected)
      try {
        if (action === 'move_top') {
          await this.moveTop({ids: ids})
          this.onResetSelected()
        } else if (action === 'move_bottom') {
          await this.moveBottom({ids: ids})
          this.onResetSelected()
        } else if (action === 'move_before') {
          await this.reorder({ids: ids, before: this.contextMedia.id})
          this.onResetSelected()
        } else if (action === 'move_after') {
          await this.reorder({ids: ids, after: this.contextMedia.id})
          this.onResetSelected()
        } else if (action === 'set_gallery')
          this.openSetGalleryModal(ids)
        else if (action === 'copy_to_gallery')
          this.openCopyToGalleryModal(ids)
        else if (action === 'add_tags')
          this.openAddTagsModal(ids)
        else if (action === 'delete_media') {
          await this.confirm({
            color: 'danger',
            title: this.$pgettext('photo_search', 'Delete files'),
            message: this.$pgettext('photo_search', 'The selected content will be permanently and irreversibly removed from users\' albums.'),
            cb_confirm: async () => {
              try {
                await this.deleteMedia(ids)
                this.onResetSelected()
              } catch (e) {
                await this.handleActionErrors(e)
              }
            }
          })
        } else if (action === 'remove_tags') {
          await this.removeTags({ids: ids})
          this.onResetSelected()
        } else if (action === 'download') {
          await this.downloadPhotos({ids: ids})
          this.onResetSelected()
        } else if (action === 'soft_delete') {
          await this.confirm({
            color: 'danger',
            icon: 'cipEyeOff',
            title: this.$pgettext('photo_search', 'Hide photo'),
            message: this.$pgettext('photo_search', 'Are you sure you want to hide this photo? It will no longer be visible to all users but you can restore it whenever you want.'),
            cb_confirm: async () => {
              try {
                await this.softDelete(ids)
                this.onResetSelected()
              } catch (e) {
                await this.handleActionErrors(e)
              }
            }
          })
        } else if (action === 'restore') {
          await this.confirm({
            color: 'primary',
            icon: 'cipEye',
            title: this.$pgettext('photo_search', 'Show photo'),
            message: this.$pgettext('photo_search', 'Are you sure you want to show this photo?'),
            cb_confirm: async () => {
              try {
                await this.restore(ids)
                this.onResetSelected()
              } catch (e) {
                await this.handleActionErrors(e)
              }
            }
          })
        } else
          console.log('unknown action ' + action)
      } catch (e) {
        await this.handleActionErrors(e)
      }
    },
    onDownload({ids, email, applyLogo}) {
      this.downloadPhotos({ids: ids, email: email, applyLogo: applyLogo})
    },
    async handleActionErrors(e) {
      if (e instanceof ValidationError) {
        switch (e.getCode()) {
          case 'non_deletable_media':
            return await this.notifyError(
                this.$gettextInterpolate(
                    this.$pgettext('photo_search', 'Some files cannot be deleted: %{ids}'),
                    {ids: e.getData().join(', ')}
                )
            )
        }
      }
      throw e
    },
    async routeToOverview() {
      if (this.$route.name === 'eventWizard.overview') return
      await this.$router.push({name: 'eventWizard.overview', params: {picaServiceId: this.picaServiceId}})
    }
  }
}
</script>
<style scoped>
.no-select {
  user-select: none;
}
</style>