<template>
  <CModal :show.sync="show" centered>
    <template #header>
      <div>
        <div class="d-flex">
          <CIcon name="cipUpload2" size="custom-size" width="36" height="36"
                 class="mr-3 icon-circle icon-circle--primary"/>
          <div class="align-self-center">
            <h5 class="modal-title" v-translate translate-context="globalUploader.receiver.modal.title">Upload files</h5>
          </div>
        </div>
      </div>
      <CButtonClose @click="show = false"/>
    </template>

    <template v-if="loading">
      Analyzing files...
    </template>
    <template v-else>
      <div v-if="numFiles > 0" class="mb-3">
         <translate translate-context="globalUploader.uploading" :translate-params="{numFiles: numFiles, totalSize: totalSizeHuman}">
           You are about to upload %{numFiles} files for a total of %{totalSize}.</translate>

      </div>
      <UploadErrors :files="errors"/>
    </template>
    <template #footer>
      <CButton color="secondary" @click="show = false" v-translate>Cancel</CButton>
      <PButton
          v-if="numFiles > 0"
          color="primary"
          class="mr-3"
          :disabled="loading"
          @click="startSession">
        Upload
      </PButton>
    </template>
  </CModal>
</template>

<script>
import {mapActions} from "vuex";
import PButton from "@/components/PButton.vue";
import UploadErrors from "@/domain/uploader/components/UploadErrors.vue";

export default {
  name: 'UploadReceiver',
  components: {UploadErrors, PButton},
  data() {
    return {
      files: [],
      show: false,
      loading: false
    }
  },
  computed: {
    totalSize() {
      return this.files.reduce((acc, file) => acc + file.size, 0)
    },
    totalSizeHuman() {
      return this.$options.filters.formatSize(this.totalSize)
    },
    numFiles() {
      return this.files.filter(file => !file.error).length
    },
    errors() {
      return this.files.filter(file => file.error)
    },
  },
  methods: {
    ...mapActions('uploader', [
      'validateFiles',
      'createSession'
    ]),
    async onFileDrop(files) {
      this.show = true
      this.loading = true
      this.files = []
      this.files = await this.validateFiles(files)
      this.loading = false
    },
    async startSession() {
      await this.createSession(this.files.filter(file => !file.error))
      this.show = false
    }
  },
}
</script>