<template>
  <CRow>
    <CCol col="12">

      <div class="timeline mb-2" v-if="timeline">
        <div class="p-2 d-flex justify-content-between">
          <div class="overflow-auto">
            <PNav :items="clusterTypes"
                  :value="clusterBy"
                  @update:value="onChangeClusterType"
                  extra-class="chart-pills chart-pills--sm"/>
          </div>
          <div class="timeline__tz mr-2">
            {{ timeZone }}
          </div>
        </div>
        <div class="position-relative" ref="tlWrap">
          <div class="timeline__scroll"
               ref="tlScrollable"
               id="tlScrollable"
               @scroll="onScroll"
               @mousedown="onStartDrag"
               @mousemove="onDrag"
               @mouseup="onEndDrag"
               @mouseleave="onEndDrag"
          >
            <div class="timeline__item" v-for="item in items" :key="item.key" :id="item.id" ref="timelineItems">
              <div
                  class="timeline__item-content" :style="{height: item.percent + '%'}"
                  @mouseup="onClickItem(item)"
                  v-c-tooltip="{'content':getTTContent(item.tooltip), 'appendToBody': true, 'html': true}"
                  :class="{'active': item.active}"
              ></div>
              <div class="timeline__item-sep" v-if="item.separator"><span>{{ item.name }}</span></div>
            </div>
          </div>
          <CButton class="timeline__nav prev" @click="scrollTimeline('left')" :disabled="!canScrollLeft">
            <CIcon name="cipChevronLeft" size="custom-size" :width="15"/>
          </CButton>
          <CButton class="timeline__nav next" @click="scrollTimeline('right')" :disabled="!canScrollRight">
            <CIcon name="cipChevronLeft" size="custom-size" :width="15"/>
          </CButton>
        </div>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import PNav from "@/domain/core/components/PNav.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "TimeLine",
  components: {PNav},
  emits: ['update:activeKey'],
  props: {
    activeKey: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      canScrollLeft: false,
      canScrollRight: false,
      drag: {
        dragging: false,
        dragStart: 0,
        dragged: 0,
        scrollStart: 0
      }
    }
  },
  watch: {
    activeKey(key) {
      this.scrollToKey(key)
    }
  },
  async mounted() {
    this.onScroll()
  },
  computed: {
    ...mapGetters('photoSearch', [
      'timeline',
      'clusterBy',
      'timeZone'
    ]),
    clusterTypes() {
      return [
        {value: 'minute', label: 'Minute'},
        {value: '10minute', label: '10 Minutes'},
        {value: 'hour', label: 'Hour'},
        {value: 'day', label: 'Day'},]
    },
    items() {
      const maxMediaPerRow = this.timeline.reduce((max, item) => {
        return item.num > max ? item.num : max
      }, 0)

      return this.timeline.map((item, index) => {
        let percent = item.num / maxMediaPerRow * 100

        item.name = this.getRowName(item)
        item.percent = percent < 6 ? 6 : percent // Avoid making the row too small
        item.id = `timeline-${item.key}`
        item.active = item.key === this.activeKey
        item.separator = index % 7 === 0 && index !== 0
        item.tooltip = this.getTooltip(item)
        return item
      })
    }
  },
  methods: {
    ...mapActions('photoSearch', [
      'setClusterBy',
    ]),

    async onChangeClusterType(type) {
      await this.setClusterBy(type)
      await this.$nextTick()
      this.scrollToKey(this.activeKey)
    },
    getRowName(row) {
      if (this.clusterBy === 'minute')
        return row.date.format('YYYY-MM-DD HH:mm')
      else if (this.clusterBy === '10minute')
        return row.date.minute(Math.floor(row.date.minute() / 10) * 10).format('YYYY-MM-DD HH:mm')
      else if (this.clusterBy === 'hour')
        return row.date.format('YYYY-MM-DD HH')
      else if (this.clusterBy === 'day')
        return row.date.format('YYYY-MM-DD')
    },
    getTooltip(row) {
      return `${row.name}<br>${row.num} photos`
    },
    onClickItem(row) {
      this.clearTooltip()
      if (this.drag.dragging && Math.abs(this.drag.dragging) > 5) return
      this.$emit('update:activeKey', row.key)
    },
    scrollToKey(key) {
      if (!key) return

      const elScrollable = this.$refs.tlScrollable
      const elScrollableWrap = this.$refs.tlWrap

      const elRow = document.querySelector(`#timeline-${key}`)
      if (!elRow) return

      const actualScroll = elScrollable.scrollLeft
      const endScroll = elRow.offsetLeft - elScrollableWrap.offsetWidth / 2
      const scrollAmount = endScroll - actualScroll
      elScrollable.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      })
    },
    onScroll() {
      const elScrollable = this.$refs.tlScrollable
      const elScrollableWrap = this.$refs.tlWrap
      if (!elScrollable || !elScrollableWrap) return
      this.canScrollLeft = elScrollable.scrollLeft > 0
      this.canScrollRight = elScrollable.scrollLeft + elScrollableWrap.offsetWidth < elScrollable.scrollWidth
    },
    scrollTimeline(direction) {
      const elScrollableWrap = this.$refs.tlWrap
      const elScrollable = this.$refs.tlScrollable
      let scrollAmount = elScrollableWrap.offsetWidth
      elScrollable.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      })
    },
    onStartDrag(event) {
      this.drag.dragging = true
      this.drag.dragStart = event.clientX
      this.drag.scrollStart = this.$refs.tlScrollable.scrollLeft
    },
    onDrag(event) {
      if (!this.drag.dragging) return
      this.drag.dragged = this.drag.dragStart - event.clientX
      this.$refs.tlScrollable.scrollLeft = this.drag.scrollStart + this.drag.dragged
    },
    onEndDrag: async function () {
      if (!this.drag.dragging) return

      if (Math.abs(this.drag.dragged) < 20) {
        this.drag.dragging = false
        this.drag.dragged = 0
        return
      }

      const row = this.findMiddleRow()
      if (row) this.$emit('update:activeKey', row.key)

      await this.delay(100)
      this.drag.dragging = false
      this.drag.dragged = 0
    },
    findMiddleRow() {
      const elScrollableWrap = this.$refs.tlWrap
      const elScrollable = this.$refs.tlScrollable
      const middle = elScrollableWrap.offsetWidth / 2
      const middleScroll = elScrollable.scrollLeft + middle

      let closest = null
      let closestDistance = Infinity

      this.$refs.timelineItems.forEach((item) => {
        const itemMiddle = item.offsetLeft + item.offsetWidth / 2
        const distance = Math.abs(itemMiddle - middleScroll)

        if (distance < closestDistance) {
          closest = item
          closestDistance = distance
        }
      })

      if (!closest || !closest.id) return null
      return this.items.find(item => item.id === closest.id)
    },
    getTTContent(content) {
      return `<div class="timeline__tt">${content}</div>`
    },
    clearTooltip() {
      // remove all tooltips attached to body
      document.querySelectorAll('.tooltip-old').forEach(function (element) {
        element.classList.remove('show');
      });
    },
  }
}
</script>
