<template>
  <div class="d-flex align-items-center justify-content-center">
    <transition name="slide-up">
      <BottomBarAll :numSelected="numSelected"
                    :actions="actions"
                    :activeTab="activeTab"
                    @reset="$emit('reset')"
                    @action="$emit('action', $event)"
                    v-if="activeTab === photoSearchTab.all && isVisible"/>
      <BottomBarUnprocessable @deliver="$emit('deliver')"
                              v-if="activeTab === photoSearchTab.unprocessable"/>
      <BottomBarHidden @show="$emit('show')"
                       v-if="activeTab === photoSearchTab.deleted && hasMedia"/>
    </transition>
  </div>

</template>
<script>
import BottomBarHidden from "@/domain/photoSearch/components/BottomBarHidden.vue";
import BottomBarUnprocessable from "@/domain/photoSearch/components/BottomBarUnprocessable.vue";
import BottomBarAll from "@/domain/photoSearch/components/BottomBarAll.vue";
import photoSearchTab from "@/domain/core/constant/photoSearchTab";
import {mapGetters} from "vuex";

export default {
  name: "BottomBar",
  emits: ['reset', 'action', 'deliver', 'show'],
  components: {BottomBarAll, BottomBarUnprocessable, BottomBarHidden},
  props: {
    numSelected: {
      type: Number,
      required: true
    },
    actions: {
      type: Array,
      required: true
    },
    activeTab: {
      type: String,
      required: true
    },
  },
  computed: {
    ...mapGetters('photoSearch', [
      'hasMedia',
      'searchResult'
    ]),
    photoSearchTab() {
      return photoSearchTab
    },
    isVisible() {
      if (this.activeTab === photoSearchTab.all) {
        return this.numSelected > 0 || this.searchResult
      }
      return true
    }
  }
}
</script>



