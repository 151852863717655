<template>
  <CModal :show.sync="show" :title="$pgettext('browse_photo', 'Filter')" centered>
    <PComponentLoader :loading="loading">
      <template v-if="!loading">
        <PFRow :label="$pgettext('photo_search.label','Photographer')"
               size="12x12"
               v-if="permissions.canFilterByPhotographers">
          <PSelectKeyValue
              :options="options.photographers"
              :value.sync="value.photographer_id"
              allow-empty
          />
        </PFRow>
        <PFRow :label="$pgettext('photo_search.label','Tags')"
               size="12x12"
               v-if="permissions.canFilterByTags && options.tags.length">
          <PTagInput v-model="value.tags" :allowed_tags="options.tags"/>
        </PFRow>
        <PFRow :label="$pgettext('photo_search.label','Date')"
                size="12x12"
               v-if="permissions.canFilterByShotAt"
        >
          <VueCtkDateTimePicker
              v-model="value.date_range"
              :no-label="true"
              :range="true"
              format="YYYY-MM-DD HH:mm"
              formatted="LLL"
              color="#1867FF"
               class="mb-2"
          />
        </PFRow>
        <PFRow
            size="12x12"
            :label="$pgettext('photo_search.label','Pica Code')"
            v-if="permissions.canFilterByPicaCode"
        >
          <CInput v-model="value.pica_code" :placeholder="$pgettext('photo_search.placeholder','Pica Code')"/>
        </PFRow>
        <PFRow
            size="12x12"
            :label="$pgettext('photo_search.label','Race Number')"
            v-if="permissions.canFilterByRaceNumber"
        >
          <CInput v-model="value.race_number" :placeholder="$pgettext('photo_search.placeholder','Race Number')"/>
        </PFRow>
        <PFRow
            size="12x12"
            :label="$pgettext('photo_search.label','Delivery Status')"
            v-if="permissions.canFilterByDeliveryStatus"
        >
          <PSelectKeyValue
              :options="deliveryStatusOptions"
              :value.sync="value.delivery_status"
              allow-empty
          />
        </PFRow>
      </template>
    </PComponentLoader>
    <template #footer>
      <CButton color="secondary" @click="show = false" v-translate>Close</CButton>
      <CButton color="primary" @click="onFilter" v-translate>Search</CButton>
    </template>
  </CModal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {cloneDeep} from "lodash";
import PComponentLoader from "@/domain/core/components/PComponentLoader.vue";
import PFRow from "@/domain/core/components/PFRow.vue";
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";
import PTagInput from "@/domain/core/components/PTagInput.vue";
import deliveryStatus from "@/domain/core/constant/deliveryStatus";

export default {
  name: "FilterModal",
  components: {PTagInput, PSelectKeyValue, PFRow, PComponentLoader},
  emits: ['filter'],
  computed: {
    deliveryStatusOptions() {
      return deliveryStatus.options()
    },
    ...mapGetters('photoSearch', [
      'permissions',
      'filters',
      'options'
    ]),
  },
  data() {
    return {
      show: false,
      loading: true,
      value: {
        photographer_id: null,
        tags: null,
        pica_code: null,
        date_range: {
          start: null,
          end: null
        },
        delivery_status: null,
      },
    }
  },
  methods: {
    ...mapActions('photoSearch', [
      'fetchFilterOptions',
    ]),
    async open() {
      this.value = cloneDeep(this.filters)
      this.show = true
      if (!this.options)
        await this.fetchFilterOptions()
      this.loading = false
    },
    async onFilter() {
      await this.emitPromised('filter', this.value)
      this.show = false
    }
  }
}
</script>