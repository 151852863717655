<template>
  <div class="upload-bottom-bar">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center" :class="[searchResult ? 'mr-2' : 'mr-4']" v-if="numSelected > 0">
        <translate :translate-n="numSelected" translate-plural="%{ numSelected } photos selected">
          %{ numSelected } photo selected
        </translate>
        <CButtonClose class="list-item-closer ml-2" @click="$emit('reset')">
          <CIcon name="cipClose" size="custom-size" width="15"/>
        </CButtonClose>
      </div>
      <div class="d-flex align-items-center nav-res mr-3" v-if="searchResult">
        <CButton color="secondary" @click="prevSearchResult" class="ml-2 btn-circle btn-circle--xs"
                 :disabled="!searchResult.hasPrev">
          <CIcon name="cipChevronLeft" size="lg"/>
        </CButton>
        <span class="ml-2">{{ searchResult.actual }} / {{ searchResult.total }}</span>
        <CButton color="secondary" @click="nextSearchResult" class="ml-2 btn-circle btn-circle--xs"
                 :disabled="!searchResult.hasNext">
          <CIcon name="cipChevronLeft" size="custom-size" style="transform: rotate(180deg);"/>
        </CButton>
      </div>
      <div>
        <PDropDown
            color="secondary"
            :items="actions"
            v-if="showActions"
            :button-if-single-item="true"
            :reset-on-select="true"
            :empty-label="$pgettext('uploader', 'Action')"
            @change="$emit('action', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PDropDown from "@/domain/core/components/PDropDown.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "BottomBarAll",
  components: {PDropDown},
  emits: ['reset', 'action'],
  props: {
    numSelected: {
      type: Number,
      required: true
    },
    actions: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters('photoSearch', [
      'searchResult',
    ]),
    showActions() {
      return (this.numSelected > 0 || this.searchResult) && this.actions.length > 0
    },
  },
  methods: {
    ...mapActions('photoSearch', [
      'nextSearchResult',
      'prevSearchResult',
    ]),
  }
}
</script>