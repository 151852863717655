<template>
  <div class="upload-bottom-bar" v-if="isVisible">
    <div class="d-flex align-items-center justify-content-between">
      <div class="mr-4">
        <translate :translate-n="unprocessable" translate-plural="%{ unprocessable } photos not delivered">
          %{ unprocessable } photo not delivered
        </translate>
      </div>
      <CButton color="primary" @click="$emit('deliver')" class="ml-2" v-if="unlockInfo">
        <translate>Deliver for</translate>
        {{ unlockInfo.price|currency(paymentCurrency) }}
      </CButton>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "BottomBarUnprocessable",
  emits: ['deliver'],
  data() {
    return {
      isVisible: false
    }
  },
  computed: {
    ...mapGetters('uploader', [
      'unprocessable',
    ]),
    ...mapGetters('eventWizard', [
      'unlockInfo',
      'paymentCurrency',
      'order'
    ]),
  },
  async mounted() {
    await this.fetchUnlockInfo()
    await this.fetchPaymentCurrency()

    await this.delay(500)
    this.isVisible = true
  },
  methods: {
    ...mapActions('eventWizard', [
      'fetchUnlockInfo',
      'fetchPaymentCurrency',
    ])
  }
}
</script>