<template>
  <CModal :show.sync="show" centered size="lg">
    <template #header>
      <div>
        <div class="d-flex">
          <div class="align-self-center">
            <h5 class="modal-title">
              {{ data.fileName }}
              <span v-if="permissions.canSeeId && data.photo" class="ml-2">
                [ ID {{ data.photo.id }} ]
              </span>
            </h5>
          </div>
        </div>
      </div>
      <CButtonClose @click="close"/>
    </template>
    <div class="image-container">
      <transition :name="transitionName" mode="out-in">
        <img class="modal-preview-image" :src="data.src" alt="" v-if="showImage"/>
      </transition>
    </div>

    <CLink @click="onChange('prev')" class="nav-photo nav-photo--prev" :disabled="!data.has_prev">
      <CIcon name="cipChevronLeft" size="custom-size" :width="36"/>
    </CLink>
    <CLink @click="onChange('next')" class="nav-photo nav-photo--next" :disabled="!data.has_next">
      <CIcon name="cipChevronRight" size="custom-size" :width="36"/>
    </CLink>
    <template #footer>
      <div class="d-flex justify-content-between align-items-end w-100">
        <div class="d-flex">
          <CIcon name="cipPin" size="custom-size" :width="22" class="icon-filled text-primary mr-2"
                 v-if="data.is_public"/>
          <div>
            <div style="font-size: 12px">{{ data.shot_at }}</div>
            <div v-if="data.photo && !data.photo.processed_at">
              <CBadge color="light" class="badge-outline mt-2">
                <CIcon name="cipDot" size="custom-size" :height="8" class="mr-2 text-warning" style="vertical-align: baseline"/>
                <span v-translate>Photo not delivered</span>
              </CBadge>
            </div>
            <div v-if="data.photo && data.photo.soft_deleted">
              <CBadge color="light" class="badge-outline mt-2">
                <CIcon name="cipEyeOff" size="custom-size" :height="14" class="mr-2" style="vertical-align: middle"/>
                <span v-translate>Photo hidden</span>
              </CBadge>
            </div>
            <div v-if="requireDetailInfo" class="mt-2" style="min-height: 30px">
              <template v-if="detail">
                <template v-if="permissions.canSeeDelivered">
                  <CBadge color="success" class="badge-outline mr-2">
                    <span v-translate>Delivered: </span>
                    <span>{{ detail.num_delivered }}</span>
                  </CBadge>
                </template>
                <template v-if="permissions.canSeeDeliverable">
                  <CBadge color="warning" class="badge-outline mr-2">
                    <span v-translate>Deliverable: </span>
                    <span>{{ detail.faces_count }}</span>
                  </CBadge>
                </template>
              </template>
              <CIcon name="cipCircleLoader" size="custom-size" :width="16" class="rotating" v-else/>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <CButton @click="show = false" color="secondary" class="mr-2" v-translate>Close</CButton>
          <CButton @click="downloadSingle(data.photo)" color="primary" v-if="permissions.canDownload">
            Download <CIcon name="cipDownload"/>
          </CButton>
        </div>
      </div>
    </template>
  </CModal>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import detail from "@/domain/eventWizard/components/folderMapping/Detail.vue";

export default {
  name: "ZoomModal",
  emits: ['download', 'change'],
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    detail() {
      return detail
    },
    ...mapGetters('photoSearch', [
      'permissions',
      'detail'
    ]),
    requireDetailInfo() {
      return this.permissions.canSeeDelivered
          || this.permissions.canSeeDeliverable
          || this.permissions.canSeeCheckin
    }
  },
  data() {
    return {
      show: false,
      showImage: true,
      transitionName: ''
    }
  },
  methods: {
    ...mapActions('photoSearch', [
      'fetchMediaDetail'
    ]),
    open() {
      this.show = true
      document.addEventListener('keydown', this.handleKeydown)
      this.onOpenImage()
    },
    close() {
      this.show = false
      document.removeEventListener('keydown', this.handleKeydown)
    },
    downloadSingle(photo) {
      this.$emit('download', photo)
    },
    async onChange(direction) {
      this.transitionName = direction === 'prev' ? 'slide-horizontal-left' : 'slide-horizontal-right'
      await this.$nextTick()
      this.showImage = false
      await this.$nextTick()
      await this.delay(200)
      this.showImage = true
      this.$emit('change', direction)
      await this.onOpenImage()
    },
    handleKeydown(event) {
      if (event.key === 'ArrowLeft' && this.data.has_prev) {
        this.onChange('prev')
      } else if (event.key === 'ArrowRight' && this.data.has_next) {
        this.onChange('next')
      }
    },
    async onOpenImage() {
      if (!this.requireDetailInfo) return
      await this.fetchMediaDetail(this.data.photo.id)
    }
  }
}
</script>