<template>
  <CModal :show.sync="show" :title="$pgettext('browse_photo', 'Search')" centered>

    <PFRow :label="$pgettext('photo_search.label','File Name')" size="12x12" v-if="permissions.canSearchByFileName">
      <CInput v-model="query.file_name" :placeholder="$pgettext('photo_search.placeholder','File Name')"/>
    </PFRow>

    <template #footer>
      <CButton color="secondary" @click="show = false" v-translate>Close</CButton>
      <CButton color="primary" @click="onSearch" v-translate>Search</CButton>
    </template>
  </CModal>
</template>

<script>
import {mapGetters} from "vuex";
import {cloneDeep} from "lodash";
import PFRow from "@/domain/core/components/PFRow.vue";

export default {
  name: "SearchModal",
  components: {PFRow},
  emits: ['filter'],
  computed: {
    ...mapGetters('photoSearch', {
      permissions: 'permissions',
      actualQuery: 'searchQuery',
    }),
  },
  data() {
    return {
      show: false,
      loading: true,
      query: {
        file_name: null,
      },
    }
  },
  methods: {
    async open() {
      this.query = cloneDeep(this.actualQuery)
      this.show = true
      this.loading = false
    },
    async onSearch() {
      await this.emitPromised('search', this.query)
      this.show = false
    }
  }
}
</script>