<template>
  <div class="upload-bottom-bar" v-if="isVisible">
    <div class="d-flex align-items-center justify-content-between">
      <div class="mr-4">
        <translate :translate-n="numMedia" translate-plural="%{ numMedia } photos hidden">
          %{ numMedia } photo hidden
        </translate>
      </div>
      <CButton color="primary" @click="$emit('show')" class="ml-2">
        <translate translate-context="browse_photo">Show all</translate>
        <CIcon size="custom" width="16" name="cipEye" class="ml-1" style="vertical-align: sub"/>
      </CButton>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "BottomBarHidden",
  emits: ['show'],
  data() {
    return {
      isVisible: false
    }
  },
  computed: {
    ...mapGetters('photoSearch', [
      'numMedia',
    ]),
  },
  async mounted() {
    await this.delay(500)
    this.isVisible = true
  }
}
</script>