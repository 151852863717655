<template>
  <CModal :show.sync="show" centered :title="title">
    <template v-if="setup">
      <PFRow :label="$pgettext('photo_search.label','E-Mail')"
             size="12x12"
             v-if="requiresEmail">
          <CInput v-model="email" placeholder="E-Mail"/>
        </PFRow>
    </template>
    <template v-else>
      <template v-if="!requiresEmail">
        <CAlert color="primary" class="text-center">
          <b>Preparing Download</b><br>
          The download will be ready in a moment<br>
        </CAlert>
      </template>
      <template v-else>
        <CAlert color="primary" class="text-center">
          <b>Preparing Download</b><br>
          The download link will be sent to the e-mail when ready<br>
          You can close now this modal<br>
        </CAlert>
      </template>
    </template>
    <template #footer>
      <CButton color="secondary" @click="show = false">Close</CButton>
      <CButton color="primary" @click="onDownload" v-if="setup">
        <span v-if="requiresEmail">Send</span>
        <span v-else>Download</span>
      </CButton>
    </template>
  </CModal>
</template>

<script>
import {mapGetters} from "vuex";
import PFRow from "@/domain/core/components/PFRow.vue";

export default {
  name: "DownloadModal",
  components: {PFRow},
  props: {
    selected: {
      type: Object,
      required: true
    },
  },
  computed: {
    ...mapGetters('login', {
      defaultEmail: 'email'
    }),
    ...mapGetters('event', [
      'currentEventName'
    ]),
    title() {
      return this.requiresEmail ? this.$pgettext('photo_search.label', 'Send download link') : this.$pgettext('photo_search.label', 'Download')
    }
  },
  data() {
    return {
      show: false,
      setup: true,
      email: '',
      url: null,
      requiresEmail: false
    }
  },
  methods: {
    open() {
      this.requiresEmail = Object.keys(this.selected).length > 200
      this.url = null
      this.setup = true
      if (!this.requiresEmail) {
        this.onDownload()
      } else {
        this.show = true
        this.email = this.defaultEmail
      }
    },
    async onDownload() {
      const ids = Object.keys(this.selected)
      this.setup = false
      await this.emitPromised('download', {ids: ids, email: this.requiresEmail ? this.email : null})
      if (this.requiresEmail) {
        this.show = false
      }
    },
  }
}
</script>
<style scoped>
.photo-overlay {
  width: 100%;
  height: 100%;
  background-color: #2e3037;
  opacity: 40%;
  position: absolute;
  left: 0px;
  top: 0px
}

.photo-check {
  position: absolute;
  top: 7px;
  left: 20px;
  color: #ffffff
}

.photo-zoom {
  position: absolute;
  top: 7px;
  right: 20px;
  color: #ffffff;
  display: none;
}

.photo-grid-image:hover .photo-zoom {
  display: block;
}

.photo-grid-image {
  cursor: pointer;
  padding-bottom: 30px;
}
</style>