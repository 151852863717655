<template>
  <CModal :show.sync="show" centered>
    <PComponentLoader :loading="loading">
      <template v-if="show && !loading">
        <PTreeTagInput v-model="tags" :allowed_tags="availableTags"/>
      </template>

    </PComponentLoader>
    <template #footer>
        <CButton @click="show = false" color="secondary" v-translate>Close</CButton>
        <PButton @click="onAddTags" color="primary" translate-context="photo_search.add_tags" v-translate>Add
          Tags
        </PButton>
      </template>
  </CModal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PComponentLoader from "@/domain/core/components/PComponentLoader.vue";
import PTreeTagInput from "@/domain/core/components/PTreeTagInput.vue";
import PButton from "@/components/PButton.vue";

export default {
  name: "TagSelectorModal",
  components: {PButton, PTreeTagInput, PComponentLoader},
  data() {
    return {
      loading: true,
      show: false,
      tags: [],
      ids: [],
    }
  },
  computed: {
    ...mapGetters('photoSearch', [
      'availableTags',
    ]),
  },
  emits: ['add'],
  methods: {
    ...mapActions('photoSearch', [
      'fetchTags',
    ]),
    async open(ids) {
      this.loading = true
      this.show = true
      this.tags = []
      this.ids = ids
      await this.fetchTags()
      this.loading = false
    },
    async onAddTags() {
      await this.emitPromised('add', {tags: this.tags, ids: this.ids})
      this.show = false
    }
  }
}
</script>

<style scoped>

</style>