<template>
  <div class="photo-browser-card j-media"
       :class="mediaItemClass"
       :draggable="canSort"
       @mouseover="onMouseOver"
       @dragstart="handleDragStart"
       @dragover.prevent="handleDragOver"
       @contextmenu.prevent="handleContextMenu"
       @click="onClick"
       :data-media-id="photo.id">
    <div class="photo-browser-card__check j-check">
      <CIcon name="cipCheckbox" size="custom-size" :width="20" :class="['checkbox-icon',{'selected': selected}]"/>
    </div>
    <div class="photo-browser-card__image" :class="thumbClass">
      <CImg
          :draggable="false"
          v-if="preLoadable || imageLoaded"
          :src="photo.thumb"
          fluid
          @load="onImageLoad"
          loading="lazy"
      />
      <CImg v-else :src="require('@/assets/images/event_wizard/photoSearch/ph.png')"/>

      <!-- zoom -->
      <CLink class="photo-browser-card__bt-zoom j-zoom" v-if="selected">
        <CIcon name="cipResize" size="custom-size" class="text-primary" :width="22"/>
      </CLink>
      <!-- tags -->
      <div class="d-flex align-items-center justify-content-between photo-browser-card__tags"
           v-if="mediaTags(photo.tags).length > 0 && !selected">
        <CBadge v-for="tag in mediaTags(photo.tags)" :key="tag" shape="pill" color="primary-light no-delete ml-1">
          {{ tag }}
        </CBadge>
      </div>
    </div>
    <div class="photo-browser-card__footer">
      <div v-if="photo.file_name" :title="photo.file_name" class="photo-browser-card__name">
        <CIcon v-if="processing" name="cipRefreshCw04" size="custom-size" :width="16"
               style="color:#8887A0;vertical-align: sub" class="rotating"/>
        <CIcon v-if="payAsYouGo" name="cipDot" size="custom-size" :width="8"
               style="vertical-align: middle" class="text-warning"/>
        <CIcon v-if="!photo.editable" name="cipLock" size="custom-size" :width="8"
               style="vertical-align: middle" class="text-warning"/>
        {{ photo.file_name }}
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import GallerySorting from "@/domain/core/constant/gallerySorting";

export default {
  name: "GridImage",
  emits: ['selected', 'unselected', 'zoom', 'dragging', 'dragOverMedia', 'contextmenu', 'hover'],
  props: {
    photo: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    },
    hovering: {
      type: Boolean,
      required: false,
      default: false
    },
    found: {
      type: Boolean,
      required: false,
      default: false
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    },
    selectionMode: {
      type: Boolean,
      required: false,
      default: false
    },
    preLoadable: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  data() {
    return {
      isLoading: true,
      imageLoaded: false
    }
  },
  computed: {
    ...mapGetters('photoSearch', ['mediaTags', 'sorting', 'permissions']),
    ...mapGetters('uploader', ['processingLimitReached']),
    canSort() {
      return this.sorting === GallerySorting.custom && this.permissions.canSortMedia
    },
    processing() {
      if (this.photo.processed_at) return false
      return !this.processingLimitReached
    },
    payAsYouGo() {
      if (this.photo.processed_at) return false
      return this.processingLimitReached
    },
    softDeleted() {
      return this.photo.soft_deleted
    },
    thumbClass() {
      return {
        'selected': this.selected,
        'processing': this.processing,
        'pay-as-you-go': this.payAsYouGo,
        'soft-deleted': this.softDeleted
      }
    },
    mediaItemClass() {
      return {
        'selected': this.selected,
        'hovering': this.hovering,
        'found': this.found,
        'found-active': this.active
      }
    }
  },
  methods: {
    handleDragStart(event) {
      this.$emit('dragging', {
        event: event,
        photo: this.photo
      })
    },
    handleDragOver() {
      this.$emit('dragOverMedia', this.photo)
    },
    handleContextMenu(event) {
      this.$emit('contextmenu', {event: event, photo: this.photo})
    },
    onImageLoad() {
      this.imageLoaded = true
    },
    onMouseOver() {
      this.$emit('hover', this.photo.id)
    },
    onClick(e) {
      const target = e.target
      if (target.closest('.j-check'))
        this.onSelect()
      else if (target.closest('.j-zoom'))
        this.onZoom()
      else if (this.selectionMode)
        this.onSelect()
      else
        this.onZoom()
    },
    onSelect() {
      if (this.selected) this.$emit('unselected', this.photo)
      else this.$emit('selected', this.photo)
    },
    onZoom() {
      this.$emit('zoom', this.photo)
    },
  }
}
</script>
