<template>
  <div v-if="badges.length>0">
    <CBadge class="mr-2"
            shape="pill"
            color="primary-light"
            v-for="badge in badges" :key="badge.key">
      {{ badge.title }} {{ badge.value }}
      <span @click="removeFilter(badge.key)">&times;</span>
    </CBadge>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import moment from "moment/moment";
import deliveryStatus from "@/domain/core/constant/deliveryStatus";

export default {
  name: "FilterBadges",
  emits: ['filter'],
  computed: {
    ...mapGetters('photoSearch', [
      'filters',
      'options'
    ]),
    ...mapGetters('event', [
      'timeZone',
    ]),
    deliveryStatus() {
      return deliveryStatus
    },
    badges() {
      return Object.keys(this.filters).map(key => {
        if (!this.filters[key]) return

        let custom = {}
        if (key === 'photographer_id') custom = this.getPhotographerIdWidget(this.filters[key])
        else if (key === 'tags') custom = this.getTagsWidget(this.filters[key])
        else if (key === 'date_range') {
          if (!this.filters[key].start && !this.filters[key].end)
            return null
          custom = this.getDateRangeWidget(this.filters[key])
        }
        else if (key === 'pica_code') custom = this.getPicaCodeWidget(this.filters[key])
        else if (key === 'race_number') custom = this.getRaceNumberWidget(this.filters[key])
        else if (key === 'selected') custom = this.getSelectedWidget(this.filters[key])
        else if (key === 'delivery_status') custom = this.getDeliveryStatusWidget(this.filters[key])
        else return null

        return {
          key: key,
          title: key,
          value: this.filters[key],
          ...custom
        }
      }).filter(value => value !== null)
    }
  },
  methods: {
    getPhotographerIdWidget(value) {
      if (!this.options.photographers) return {}
      let photographer = this.options.photographers.find(p => p.id === value)
      return {
        title: 'Photographer',
        value: photographer ? photographer.name : ''
      }
    },
    getTagsWidget(value) {
      return {
        title: 'Tags',
        value: value.join(', '),
      }
    },
    getDateRangeWidget(value) {
      let text = ''
      if (value.start && value.end)
        text = this.formatFilterDate(value.start) + ' - ' + this.formatFilterDate(value.end)
      else if (value.start)
        text = 'After ' + this.formatFilterDate(value.start)
      else if (value.end)
        text = 'Before ' + this.formatFilterDate(value.end)

      return {
        title: 'Date',
        value: text
      }
    },
    getPicaCodeWidget(value) {
      return {
        title: 'Pica Code',
        value: value
      }
    },
    getRaceNumberWidget(value) {
      return {
        title: 'Bib Number',
        value: value
      }
    },
    getSelectedWidget() {
      return {
        title: 'Selected Media',
        value: ''
      }
    },
    getDeliveryStatusWidget(value) {
      return {
        title: deliveryStatus.getLabel(value),
        value: ''
      }
    },
    removeFilter(key) {
      let newFilters = {...this.filters}
      delete newFilters[key]
      this.$emit('filter', newFilters)
    },
    formatFilterDate(date) {
      return moment(date).format('LLL')
    },
  }
}
</script>