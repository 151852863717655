<template>
  <CNav :variant="variant" :class="extraClass">
    <CNavItem v-for="item in items"
              :key="item.value"
              :active="item.value === value"
              :disabled="disabled"
              @click.native="onClick(item)">
      {{ item.label }}
    </CNavItem>
  </CNav>
</template>

<script>
export default {
  name: "PNavPills",
  emits: ['update:value'],
  props: {
    value: {
      type: [String, Number],
      required: false
    },
    items: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    extraClass: {
      type: String,
      required: false
    },
    variant: {
      type: String,
      default: 'pills'
    }
  },
  mounted() {
    this.$emit('update:value', this.value)
  },
  computed: {},
  methods: {
    onClick(item) {
      this.$emit('update:value', item.value)
    }
  }
}
</script>
