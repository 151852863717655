<template>
  <div class="text-center pre-uploader d-flex justify-content-center align-items-center">
    <div>
      <template v-if="tab === photoSearchTab.all">
        <template v-if="hasActiveFilters">
          <CIcon class="text-primary d-block mx-auto mb-2" size="custom-size" :height="30"
                 name="cipSearch2"/>
          <div class="title" v-translate>No results found</div>
          <div class="subtitle" v-translate>Try to change the search filters</div>
        </template>
        <template v-else-if="hasProcessingMedia">
          <CIcon class="text-primary d-block mx-auto mb-2" size="custom-size" :height="50"
                 name="cipCircleLoader"/>
          <div class="title" v-translate>Photos now being processed</div>
          <div class="subtitle" v-translate>Please wait, it may take a few minutes</div>
        </template>
        <template v-else>
          <CIcon class="text-primary d-block mx-auto mb-2" size="custom-size" :height="45"
                 name="cipUploadCloud"/>
          <div>
            <div class="font-weight-medium mb-1" v-translate>Drag the file here to upload</div>
            <div class="text-help mb-4" style="font-size: 12px">(<span v-translate>Allowed formats:</span>
              {{ allowedExtensions.join(', ') }})
            </div>
          </div>
          <CButton
              @click="$emit('upload')"
              size="sm" color="primary"
              v-if="permissions.canUpload">
            <CIcon name="cipUpload2" style="vertical-align: text-top"/>
            <translate>Upload</translate>
          </CButton>
        </template>
      </template>
      <template v-else-if="tab === photoSearchTab.unprocessable">
        <div class="title" v-translate>There are no undelivered files</div>
      </template>
      <template v-else-if="tab === photoSearchTab.deleted">
        <CIcon class="d-block mx-auto text-primary" size="custom-size" :height="48"
               name="cipEyeOff"/>
        <div class="title mt-2" v-translate>No hidden photos</div>
        <div class="subtitle" v-translate>All uploaded photos are visible</div>
      </template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import photoSearchTab from "@/domain/core/constant/photoSearchTab";

export default {
  name: "NoMedia",
  emits: ['upload'],
  props: {
    tab: {
      type: String,
      required: true,
    },
  },
  computed: {
    photoSearchTab() {
      return photoSearchTab
    },
    ...mapGetters('photoSearch', [
      'hasActiveFilters',
      'permissions',
    ]),
    ...mapGetters('uploader', [
      'hasProcessingMedia',
      'allowedExtensions'
    ]),
  },
}
</script>