import {translate} from "vue-gettext";

const {gettext: $gettext} = translate;

export default {
    no_face_detected: 'nf',
    no_delivery: 'nd',
    some_delivered: 'sd',
    all_delivered: 'ad',

    options() {
        return [
            {
                id: this.no_face_detected,
                name: $gettext('No Face Detected')
            },
            {
                id: this.no_delivery,
                name: $gettext('Not delivered')
            },
            {
                id: this.some_delivered,
                name: $gettext('Partially Delivered')
            },
            {
                id: this.all_delivered,
                name: $gettext('Completely Delivered')
            },
        ]
    },

    getLabel(value) {
        let options = this.options()
        let option = options.find(option => option.id === value)
        if (!option) return ''
        return option.name
    }

}