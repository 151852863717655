<template>
  <CRow v-if="visible">
    <CCol col="12">
      <PAlert color="info"
              class="mb-2"
              dismissible
              :text="uploadHelpText"
              :session-key="'alert-gallery-'+galleryId"
              icon="cipInfo"
              @dismiss="$emit('dismiss')"
              v-if="uploadHelpText && unprocessable<=0"/>
      <PAlert color="warning"
              class="mb-2"
              icon="cipAlertTriangle"
              content-align="align-items-center"
              v-if="unprocessable > 0">
        <template>
          <translate translate-context="browse_photo" :translate-params="{num: unprocessable}">
            You have uploaded %{num} more photos than are included in your selected plan: deliver them now!
          </translate>
        </template>
        <template #cta>
          <CLink @click="openPayAsYouGoModal">
            <CIcon name="cipArrowNarrowRight" size="custom" width="20"/>
          </CLink>
        </template>
      </PAlert>
    </CCol>
  </CRow>
</template>

<script>

import PAlert from "@/components/PAlert.vue";
import {mapGetters} from "vuex";

export default {
  name: "BannerMessages",
  components: {PAlert},
  emits: ['payAsYouGo', 'dismiss'],
  props: {
    gallery: {
      type: Object,
      required: false,
    }
  },
  computed: {
    ...mapGetters('photoSearch', [
      'permissions',
    ]),
    ...mapGetters('uploader', [
      'galleryStats',
      'unprocessable',
    ]),
    visible() {
      return this.uploadHelpText || this.unprocessable > 0
    },
    uploadHelpText() {
      if (!this.permissions.canUpload) return null
      if (!this.gallery) return null
      let limitMsg = ''
      if (this.galleryStats.upload_limit > 0)
        limitMsg = this.$gettextInterpolate('Upload up to %{limit} photos.', {limit: this.galleryStats.upload_limit})
      let galleryTypeMsg = ''
      if (this.gallery.is_public)
        galleryTypeMsg = this.$pgettext('event_wizard.tutorial', 'Media will be visible in the albums of all the participants')
      else
        galleryTypeMsg = this.$pgettext('event_wizard.tutorial', 'Each person will only receive and view the photos they appear in.')
      return limitMsg + ' ' + galleryTypeMsg
    },
    galleryId() {
      return this.gallery ? this.gallery.id : '0'
    }
  },
  methods: {
    openPayAsYouGoModal() {
      const parent = this.getParentWithMethod('openPayAsYouGo')
      if (parent) parent.openPayAsYouGo()
    },
  }
}
</script>