<template>
  <CRow class="upload-top-bar">
    <CCol col="12">
      <div class="d-flex flex-column flex-xl-row justify-content-xl-between align-items-xl-center">
        <div class="mb-1">
          <FilterModal ref="filterModal" @filter="onFilter"/>
          <SearchModal @search="onSearch" ref="searchModal"/>
          <div class="d-flex align-items-center">
            <CButton @click="openUpload" color="primary" v-if="canUpload" class="mr-2">
              <CIcon name="cipUpload2"/>
              <translate>Upload</translate>
            </CButton>
            <CButton @click="openFilter" color="secondary" class="mr-2" v-if="canFilter">
              <CIcon name="cipSettings"/>
              <translate>Filter</translate>
            </CButton>
            <CButton @click="openSearch" color="secondary" class="mr-2" v-if="canSearch">
              <CIcon name="cipSearch2"/>
              <translate>Search</translate>
            </CButton>
            <div class="d-flex align-items-center ml-2 text-primary" v-if="hasProcessingMedia">
              <CIcon name="cipRefreshCw04" class="rotating mx-2"/>
              <span v-translate translate-context="photoSearch.processing.media_present">Processing files</span>
            </div>
          </div>
        </div>
        <PTabs pills class="tabs-unprocessable mb-1"
               :tabs="tabs"
               :selected="activeTab"
               @select="$emit('changeTab', $event)"
               v-if="tabs.length > 1"/>
        <div class="d-flex align-items-center mb-1">
          <div class="ml-2">
            <PDropDown
                color="secondary"
                :items="sortOptions"
                :value="sorting"
                reset-on-select
                show-item-selector
                :empty-label="$pgettext('photoSearch.sort', 'Sort')"
                v-if="sortOptions.length > 1"
                @change="$emit('sorting', $event)"
            />
          </div>
          <div class="ml-2">
            <PDropDown
                color="secondary"
                reset-on-select
                show-item-selector
                :value="scale"
                :items="scaleActions"
                :empty-label="$pgettext('photoSearch', 'View')"
                @change="$emit('scale', $event)"
            />
          </div>
        </div>
      </div>
      <FilterBadges class="mb-2" @filter="onFilter"/>
    </CCol>
  </CRow>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import FilterModal from "@/domain/photoSearch/components/FilterModal.vue";
import SearchModal from "@/domain/photoSearch/components/SearchModal.vue";
import FilterBadges from "@/domain/photoSearch/components/FilterBadges.vue";
import PDropDown from "@/domain/core/components/PDropDown.vue";
import PTabs from "@/domain/core/components/PTabs.vue";
import gallerySorting from "@/domain/core/constant/gallerySorting";
import photoSearchTab from "@/domain/core/constant/photoSearchTab";

export default {
  name: "TopBar",
  components: {PTabs, PDropDown, FilterBadges, SearchModal, FilterModal},
  emits: ['filter', 'search', 'upload', 'scale', 'changeTab', 'sorting'],
  props: {
    canUpload: {
      type: Boolean,
      required: true,
      default: false
    },
    scale: {
      type: Number,
      required: true
    },
    activeTab: {
      type: String,
      required: true
    },
  },
  computed: {
    ...mapGetters('photoSearch', [
      'searchResult',
      'stats',
      'permissions',
      'sorting',
      'defaultSorting'
    ]),
    ...mapGetters('uploader', [
      'hasProcessingMedia',
      'unprocessable'
    ]),
    canFilter() {
      return this.permissions.canFilterByPhotographers ||
          this.permissions.canFilterByTags ||
          this.permissions.canFilterByShotAt ||
          this.permissions.canFilterByPicaCode ||
          this.permissions.canFilterByRaceNumber ||
          this.permissions.canFilterByDeliveryStatus
    },
    canSearch() {
      return this.permissions.canSearchByFileName
    },
    sortOptions() {
      return gallerySorting.availableSorting(this.defaultSorting).map(sort => ({
        label: sort.name,
        value: sort.id
      }))
    },
    scaleActions() {
      return [{
        label: this.$pgettext('photo_search.scale', 'Small view'),
        value: 1,
        icon: 'cipGridSm'
      }, {
        label: this.$pgettext('photo_search.scale', 'Medium view'),
        value: 2,
        icon: 'cipGridMd'
      }, {
        label: this.$pgettext('photo_search.scale', 'Large view'),
        value: 3,
        icon: 'cipGridLg'
      }]
    },
    tabs() {
      let tabs = []
      tabs.push({name: photoSearchTab.all, label: this.$gettext('All photos')})
      if (this.unprocessable > 0)
        tabs.push({
          name: photoSearchTab.unprocessable,
          label: '<span class="dot"></span>' + this.$gettextInterpolate(this.$gettext('Photos not delivered (%{unprocessable})'), {unprocessable: this.unprocessable})
        })
      if (this.permissions.canSoftDelete || this.permissions.canRestore)
        tabs.push({name: photoSearchTab.deleted, label: this.$gettext('Hidden')})
      return tabs
    }
  },
  methods: {
    ...mapActions('photoSearch', [
      'setSearchQuery',
    ]),
    openFilter() {
      this.$refs.filterModal.open()
    },
    openSearch() {
      this.$refs.searchModal.open()
    },
    onFilter(value) {
      this.$emit('filter', value)
    },
    openUpload() {
      this.$emit('upload')
    },
    async onSearch(query) {
      await this.setSearchQuery(query)
      if (!this.searchResult)
        await this.notifyError('No results found')
    },
  }
}
</script>