<template>
  <div class="infinite-scroll" :class="scrollClasses" ref="infinite-container">
    <div v-if="loading.before">
      <CSpinner color="primary" size="sm"/>
    </div>
    <div ref="intersect-before"></div>
    <CRow>
      <Cluster
          :selected="selected"
          :hovering="hovering"
          :scale="scale"
          :sortingPosition="sortingPosition"
          :sortingDirection="sortingDirection"
          :preLoadable="preLoadable"
          @selected="$emit('selected', $event)"
          @unselected="$emit('unselected', $event)"
          @hover="$emit('hover', $event)"
          @dragging="$emit('dragging', $event)"
          @dragOverMedia="$emit('dragOverMedia', $event)"
          @contextmenu="$emit('contextmenu', $event)"
          @download="$emit('download',$event)"
          @scroll="onSlideShowScroll"
      />
    </CRow>
    <div ref="intersect-after"></div>
    <div v-if="loading.after">
      <CSpinner color="primary" size="sm"/>
    </div>
  </div>
</template>

<script>
// Inspired by: https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VInfiniteScroll/VInfiniteScroll.tsx
import Cluster from "@/domain/photoSearch/components/Cluster.vue";
import {mapActions, mapGetters} from "vuex";
import Vue from "vue";

export default {
  name: "InfiniteScroll",
  components: {Cluster},
  emits: ['begin', 'end', 'update:visible', 'selected', 'unselected', 'hover', 'dragging', 'dragOverMedia', 'contextmenu', 'download'],
  props: {
    hasBefore: {
      type: Boolean,
      default: true
    },
    hasAfter: {
      type: Boolean,
      default: true
    },
    selected: {
      type: Object,
      required: true
    },
    hovering: {
      type: Object,
      required: true
    },
    scale: {
      type: Number,
      default: 1
    },
    in_tab: {
      type: Boolean,
      required: false,
      default: false
    },
    has_timeline: {
      type: Boolean,
      required: false,
      default: false
    },
    sortingPosition: {
      type: Number,
      required: false,
      default: null
    },
    sortingDirection: {
      type: Number,
      required: false,
      default: null
    }
  },
  data() {
    return {
      element: {
        container: null,
        before: null,
        after: null,
      },
      observer: {
        limits: null,
        media: null,
        preLoadable: null,
      },
      loading: {
        before: false,
        after: false,
      },
      beforeUpdate: {
        scrollSize: 0,
        scrollAmount: 0
      },
      visible: {
        media: {},
        first: null,
        observed: []
      },
      preLoadable: {},
    }
  },
  watch: {
    async clusters() {
      if (this.loading.before) {
        this.storePosition()
        await this.$nextTick()
        this.restorePosition()
      }
      await this.$nextTick()
      this.observeMedia()
    }
  },
  beforeDestroy() {
    this.observer.limits.disconnect()
    this.observer.media.disconnect()
    this.observer.preLoadable.disconnect()
  },
  computed: {
    ...mapGetters('photoSearch', [
      'clusters',
    ]),
    scrollClasses() {
      return {
        'in-tab': this.in_tab,
        'has-timeline': this.has_timeline
      }
    }

  },
  updated() {
    this.observeElements()
  },
  async mounted() {
    this.observeElements()
    this.observeMedia()
  },
  methods: {
    ...mapActions('photoSearch', ['setMediaVisibility']),
    observeElements() {
      this.element.container = this.$refs["infinite-container"]
      this.element.before = this.$refs["intersect-before"]
      this.element.after = this.$refs["intersect-after"]

      this.observer.limits = new IntersectionObserver(
          this.onIntersectLimit,
          {
            root: this.element.container,
            threshold: 0.1,
            rootMargin: '600px'
          }
      )
      this.observer.limits.observe(this.element.before)
      this.observer.limits.observe(this.element.after)

      this.observer.media = new IntersectionObserver(
          this.onIntersectMedia,
          {
            root: this.element.container,
            threshold: 0.0,
            rootMargin: '200px'
          }
      )
      this.observer.preLoadable = new IntersectionObserver(
          this.onIntersectPreLoadable,
          {
            root: this.element.container,
            threshold: 0,
            rootMargin: '600px',
          }
      )
    },
    observeMedia() {
      const media = Array.from(this.element.container.querySelectorAll('.j-media'))

      this.visible.observed = this.visible.observed.filter(element => {
        if (media.includes(element)) return true
        this.observer.media.unobserve(element)
        return false
      })

      media.forEach(element => {
        if (this.visible.observed.includes(element)) return
        this.observer.media.observe(element)
        this.observer.preLoadable.observe(element)
        this.visible.observed.push(element)

        if (this.isVisibleInViewport(element)) {
          const mediaId = element.dataset.mediaId
          const cluster = element.closest('.j-cluster')
          Vue.set(this.visible.media, mediaId, cluster.dataset.key)
        }
      })
    },
    storePosition() {
      this.beforeUpdate.scrollSize = this.getScrollSize()
      this.beforeUpdate.scrollAmount = this.getScrollAmount()
    },
    restorePosition() {
      const diff = this.getScrollSize() - this.beforeUpdate.scrollSize
      this.setScrollAmount(this.beforeUpdate.scrollAmount + diff)
    },
    getScrollSize() {
      if (!this.element.container) return 0
      return this.element.container.scrollHeight
    },
    getScrollAmount() {
      return this.element.container.scrollTop
    },
    setScrollAmount(amount) {
      if (!this.element.container) return
      this.element.container.scrollTop = amount
    },
    onIntersectLimit(elements) {
      elements.forEach(element => {
        if (!element.isIntersecting) return
        if (this.element.before === element.target)
            return this.loadBefore()
        if (this.element.after === element.target)
            return this.loadAfter()
      })
    },
    onIntersectMedia(elements) {
      elements.forEach(element => {
        const mediaId = element.target.dataset.mediaId
        if (element.isIntersecting) {
          const cluster = element.target.closest('.j-cluster')
          if (cluster)
            Vue.set(this.visible.media, mediaId, cluster.dataset.key)
          else
            Vue.delete(this.visible.media, mediaId)
        }
      })
      this.onChangeVisibleMedia()
    },
    onIntersectPreLoadable(elements) {
      elements.forEach(element => {
        const mediaId = element.target.dataset.mediaId
        if (element.isIntersecting || element.intersectionRatio > 0)
          Vue.set(this.preLoadable, mediaId, true)
        else
          Vue.delete(this.preLoadable, mediaId)
      })
    },
    async loadBefore() {
      if (this.loading.before || !this.hasBefore) return
      this.loading.before = true
      await this.emitPromised('begin')
      await this.$nextTick()
      await this.$nextTick()
      this.loading.before = false
    },
    async loadAfter() {
      if (this.loading.after || !this.hasAfter) return
      this.loading.after = true
      await this.emitPromised('end')
      this.loading.after = false
    },
    onChangeVisibleMedia() {
      const keys = Object.values(this.visible.media)
      if (keys.length === 0) return null
      keys.sort()
      if (this.visible.first !== keys[0]) {
        this.visible.first = keys[0]
        this.$emit('update:visible', this.visible.first)
      }
    },
    scrollToKey(key) {
      const elements = this.element.container.querySelectorAll('.j-cluster')
      for (let i = 0; i < elements.length; i++) {
        const el = elements[i]
        if (el.dataset.key === key) {
          el.scrollIntoView({behavior: 'smooth', block: 'start'})
          return true
        }
      }
      return false
    },
    scrollToMedia(id) {
      id = id + ''
      const elements = this.element.container.querySelectorAll('.j-media')
      for (let i = 0; i < elements.length; i++) {
        const el = elements[i]
        if (el.dataset.mediaId === id) {
          el.scrollIntoView({behavior: 'smooth', block: 'start'})
          return true
        }
      }
      return false
    },
    onSlideShowScroll(media) {
      this.scrollToMedia(media.id)
    },
    isVisibleInViewport(element) {
      const rect = element.getBoundingClientRect()
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    }
  }
}
</script>
