<template>
  <div v-c-clickaway="close"
       v-if="visible"
       :style="{ top: `${position.y}px`, left: `${position.x}px` }"
       class="context-menu"
       @click.stop
  >
    <CNav vertical>
      <template v-for="(item, index) in menuItems">
        <CDropdownDivider v-if="item.divider" :key="index"/>
        <CNavItem v-else :key="index"
                  @click.native="onItemClick(item)"
                  class="context-menu__item">
         <CIcon :name="item.icon" v-if="item.icon" size="custom-size" width="18" class="item-icon" style="vertical-align: sub" />
          {{ item.label }}
        </CNavItem>

      </template>
    </CNav>
  </div>
</template>

<script>
export default {
  name: 'PContextMenu',
  props: {
    menuItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      visible: false,
      position: {
        x: 0,
        y: 0
      }
    };
  },
  methods: {
    onItemClick(itemMenu) {
      this.$emit('item-selected', itemMenu.value);
      this.close()
    },
    open(event) {
      this.position = {
        x: event.clientX,
        y: event.clientY
      }
      this.visible = true
    },
    close() {
      this.visible = false
    }
  }
}
</script>