<template>
  <div class="photo-browser-card photo-browser-card--ph j-media">
      <span v-translate>Drop here</span>
  </div>
</template>

<script>
export default {
  name: "DropPlaceholder",
}
</script>
